import Player from '@vimeo/player'
import { FC, MutableRefObject, useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'

import styles from './index.module.scss'

export type VimeoPlayerProps = {
  src: string
  title?: string
  gaTag?: string
  playerRef: MutableRefObject<Player | undefined> // useRef<Player | undefined>()
  isActive: boolean
}

export const VimeoPlayer: FC<VimeoPlayerProps> = ({
  src: url,
  title,
  gaTag,
  playerRef,
  isActive,
}) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  const onPlay = useCallback(() => {
    // GTMは一旦何もしないでおく
    // const dataLayer = window?.dataLayer
    //
    // if (!gaTag || !dataLayer) {
    //   return
    // }
    //
    // // Send GTM event
    // dataLayer.push({
    //   event: 'course_detail_cta_reproduction', // トリガーで設定したイベント名を指定
    //   onVideoPlay: gaTag, // データーレイヤー変数で作った変数名と値をKeyValueで指定
    // })
  }, [gaTag])

  useEffect(() => {
    if (!iframeRef.current) {
      return
    }

    // set up Vimeo Player
    const player = new Player(iframeRef.current)
    playerRef.current = player

    player.on('play', onPlay)
    // :note: pouse イベントがないと video 停止時も onPlay イベントが発火する
    player.on('pause', () => {
      // do nothing
    })

    return () => {
      player.off('play')
      player.off('pause')
    }
  }, [])

  return (
    <div
      className={classNames(
        styles.videoContainer,
        isActive ? styles.active : styles.hidden
      )}
    >
      <iframe
        ref={iframeRef}
        src={url}
        title={title}
        allowFullScreen={true}
        allow="autoplay; encrypted-media"
        data-ready
      />
    </div>
  )
}
