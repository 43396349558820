import React, { FC } from 'react'

import PlayArrowIcon from '../../public/svgs/play-arrow-icon.svg'

import styles from './index.module.scss'
import Image from 'next/image'

export type VidePlayerPlaceholderProps = {
  title: string
  thumbnail: string
}
export const VideoPlayerPlaceholder: FC<VidePlayerPlaceholderProps> = ({
  title,
  thumbnail,
}) => {
  return (
    <div className={styles.videoPlaceholderContainer}>
      <div className={styles.videoContainer}>
        <img
          className={styles.videoPlaceholderImage}
          src={thumbnail}
          alt={title}
          width="480"
          height="270"
        />
      </div>
      <div className={styles.videoPlaceholderCover}>
        {/* Memo: 再生ボタン込みのサムネイルのためコメントアウトする */}
        {/* <div className={styles.videoPlayIcon}>
          <Image
            width={46}
            height={77}
            src={PlayArrowIcon.src}
            alt="再生する"
          />
        </div> */}
      </div>
    </div>
  )
}
