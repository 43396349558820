import Player from '@vimeo/player'
import { FC, useCallback, useReducer, useRef } from 'react'

import {
  VideoPlayerPlaceholder,
  VidePlayerPlaceholderProps,
} from './VideoPlayerPlaceholder'
import { VimeoPlayer, VimeoPlayerProps } from './VimeoPlayer'
import styles from './index.module.scss'

type VideoPlayerContainerProps = {
  videoID: string
} & Pick<VimeoPlayerProps, 'src'> &
  VidePlayerPlaceholderProps

export const VideoPlayerContainer: FC<VideoPlayerContainerProps> = ({
  title,
  thumbnail,
  videoID,
  src,
}) => {
  const [isActiveVideo, updateActiveVideo] = useReducer(() => true, false)
  const playerRef = useRef<Player | undefined>()

  const handleActiveVideo = useCallback(() => {
    updateActiveVideo()
    const player = playerRef.current

    if (!player) {
      return
    }

    const playVideo = async () => {
      await player.play()
    }

    playVideo().catch(error => {
      // eslint-disable-next-line no-console
      console.warn('Auto play failed', error instanceof Error && { error })
    })
  }, [playerRef])

  return (
    <div className={styles.container}>
      {!isActiveVideo && (
        <button
          onClick={handleActiveVideo}
          className={styles.videoPlaceholderButton}
          aria-label={`${title}コースの動画を再生`}
        >
          <VideoPlayerPlaceholder title={title} thumbnail={thumbnail} />
        </button>
      )}
      <VimeoPlayer
        src={src}
        title={videoID}
        playerRef={playerRef}
        isActive={isActiveVideo}
      />
    </div>
  )
}
