import * as React from 'react'
import { ISODate, ISODateTime } from '../../types/date'
import classNames from 'classnames'
import moment, { Moment } from 'moment-timezone'
import styles from './index.module.scss'

type Props = {
  date: ISODateTime | ISODate | Moment
  styleName?: string
  className?: string
}

const WEEKDAY_LABELS = ['月', '火', '水', '木', '金', '土', '日']

export const FancyDate = ({ date, className }: Props) => {
  const parsedDate = moment(date).tz('Asia/Tokyo')
  const dateText = parsedDate.format('MM/DD')
  const weekday = parsedDate.isoWeekday()
  const weekdayText = WEEKDAY_LABELS[weekday - 1]
  const saturday = weekday === 6
  const sunday = weekday === 7
  return (
    <span className={styles.fancyDate}>
      {dateText}
      <span className={styles.weekdayLabel}>
        (
        <span
          className={classNames({
            [styles.sunday]: sunday,
            [styles.saturday]: saturday,
          })}
        >
          {weekdayText}
        </span>
        )
      </span>
    </span>
  )
}
// export const FancyDateTime = ({ date, className }: Props) => {
//   const parsedDate = moment(date)
//   const dateText = parsedDate.format('MM/DD')
//   const timeText = parsedDate.format('HH:mm')
//   const weekday = parsedDate.isoWeekday()
//   const weekdayText = WEEKDAY_LABELS[weekday - 1]
//   const saturday = weekday === 6
//   const sunday = weekday === 7
//   return (
//     <>
//       <span styleName="fancy-date" className={className}>
//         {dateText}
//         <span styleName={'weekday-label'}>
//           (
//           <span styleName={classNames({ sunday: sunday, saturday: saturday })}>
//             {weekdayText}
//           </span>
//           )
//         </span>{' '}
//         {timeText}
//       </span>
//     </>
//   )
// }
