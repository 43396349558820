import React from 'react'
import styles from './index.module.scss'

import cmSection from '../../public/cm_section_thumbnail.jpg'
import cmSectionLabel from '../../public/cm_section_label.png'
import { VideoPlayer } from '../VideoPlayer'

const CmSectionMovie = () => {
  return (
    <section className={styles.container}>
      <img
        src={cmSectionLabel.src}
        alt="CM Section Label"
        className={styles.label}
      />
      <h2 className={styles.text}>
        あなたは
        <br />
        どのお仕事を試したい？
      </h2>
      <VideoPlayer
        title="CM動画"
        thumbnail={cmSection.src}
        src="https://player.vimeo.com/video/1041688074"
        videoID="cmSection"
      />
    </section>
  )
}

export default CmSectionMovie
