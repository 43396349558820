import React, { useState } from 'react'
import useSWRMutation from 'swr/mutation'
import moment from 'moment-timezone'

import styles from './index.module.scss'
import classNames from 'classnames'
import { LessonData, TrialLessonIndexView } from '../../types/trialLessons'

import { Status } from './Status'
import { DAY_OF_WEEKS } from '../../types/date'
import { ChangeWeek } from './ChangeWeek'

type ItemProps = {
  setSelectedLesson: (arg: TrialLessonIndexView) => void
  lessonData?: LessonData
  isRescheduled?: boolean
  currentReservedLesson?: TrialLessonIndexView
  getNextWeekLessonData?: () => void
  getPreviousWeekLessonData?: () => void
}

export const TrialLessonCalendar = ({
  setSelectedLesson,
  lessonData,
  isRescheduled,
  currentReservedLesson,
  getNextWeekLessonData,
  getPreviousWeekLessonData,
}: ItemProps) => {
  async function getLessonInfo(url: string, { arg }: { arg: { id: string } }) {
    return fetch(`${url}${arg.id}`, {
      method: 'GET',
    }).then(res => res.json())
  }

  const { trigger } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_API_URL}/api/commons/v1/trial_lessons/`,
    getLessonInfo
  )
  const [week, setWeek] = useState(0)

  if (!lessonData) {
    return null
  }

  return (
    <>
      {getNextWeekLessonData && getPreviousWeekLessonData && (
        <ChangeWeek
          getNextWeekLessonData={getNextWeekLessonData}
          getPreviousWeekLessonData={getPreviousWeekLessonData}
          week={week}
          setWeek={(updateWeek: number) => {
            setWeek(updateWeek)
          }}
        />
      )}

      <div>
        <div className={styles.calendarHeader}>
          <div className={styles.monthRow}>
            <div className={styles.monthPlaceHolder}></div>
            {Object.keys(lessonData).map((item, i) => {
              const targetMonth = new Date(
                Object.keys(lessonData)[i - 1]
              ).getMonth()
              const nextTargetMonth = new Date(
                Object.keys(lessonData)[i]
              ).getMonth()
              // カレンダー表示が同じ月の場合は最初に生成したDOM or
              // 月末の表示で次の月が必要な場合はそれを生成する
              if (i === 0 || targetMonth !== nextTargetMonth) {
                return (
                  <div
                    key={item}
                    className={styles.month}
                    style={{
                      flex:
                        i === 0
                          ? 1
                          : (Object.keys(lessonData).length - i + 1) / (i + 1),
                    }}
                  >
                    {new Date(item).getMonth() + 1}月
                  </div>
                )
              }
            })}
          </div>
          <div className={styles.dateRow}>
            <div className={styles.dateDefinition}>開始日時</div>
            {Object.keys(lessonData).map(item => {
              const targetDate = moment.tz(item, 'Asia/Tokyo')
              const dayNum = targetDate.day()
              const date = targetDate.date()
              const dayOfWeek = DAY_OF_WEEKS[dayNum]

              const isSaturday = dayNum === 6
              const isSunday = dayNum === 0
              const isHoliday = lessonData[item].is_holiday
              return (
                <div
                  className={classNames(styles.dayOfWeek, {
                    [styles.holiday]: isHoliday,
                    [styles.saturday]: isSaturday,
                    [styles.sunday]: isSunday,
                  })}
                  key={item}
                >
                  <div>
                    <div>{dayOfWeek}</div>
                    <div className={styles.date}>{date}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={styles.calendarContent}>
          <div className={styles.timeTable}>
            {Object.keys(lessonData[Object.keys(lessonData)[0]].slots).map(
              row => (
                <div className={styles.time} key={row}>
                  {row}
                </div>
              )
            )}
          </div>

          {Object.keys(lessonData).map(date => {
            const { slots } = lessonData[date]
            return (
              <div key={date} className={styles.timeRow}>
                {Object.keys(slots)?.map(slot => {
                  const { id, status } = slots[slot]
                  const isReserved = id === currentReservedLesson?.id
                  const lessonId = id.toString()
                  return (
                    <div
                      className={styles.calendarStatus}
                      key={`${date}-${slot}`}
                    >
                      {status === 'reservable' && !isReserved ? (
                        <button
                          type="button"
                          onClick={async () => {
                            try {
                              const result = await trigger({ id: lessonId })
                              setSelectedLesson(result.body)
                              setTimeout(() => {
                                window.scroll({
                                  top: (
                                    window.document.querySelector(
                                      '#step2'
                                    ) as HTMLElement
                                  )?.offsetTop,
                                  behavior: 'smooth',
                                })
                              }, 0)
                            } catch (e) {
                              // Error handling
                            }
                          }}
                        >
                          <Status status={status} />
                        </button>
                      ) : (
                        <Status status={isReserved ? 'reserved' : status} />
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
      {getNextWeekLessonData && getPreviousWeekLessonData && (
        <ChangeWeek
          getNextWeekLessonData={getNextWeekLessonData}
          getPreviousWeekLessonData={getPreviousWeekLessonData}
          week={week}
          setWeek={(updateWeek: number) => {
            setWeek(updateWeek)
          }}
        />
      )}
      <div className={styles.statusRemarks}>
        <dl>
          <div>
            <dt>
              <Status status="reservable" />
            </dt>
            <dd>予約できます</dd>
          </div>
          <div>
            <dt>
              <Status status="full" />
            </dt>
            <dd>予約できません。他の日時を選択してください</dd>
          </div>
          {isRescheduled && (
            <div>
              <dt>
                <Status status="reserved" />
              </dt>
              <dd>すでにご予約済みです。他の日時を選択してください</dd>
            </div>
          )}
          <div>
            <dt>
              <Status status="none" />
            </dt>
            <dd>開催枠がありません</dd>
          </div>
        </dl>
      </div>
    </>
  )
}
