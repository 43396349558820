import React, { useState, ChangeEvent, useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import styles from './index.module.scss'
import { ReservationButton } from '../ReservationButton'
import InputField from './InputField'
import BirthdayInputField from './BirthdayInputField'
import SelectInputField, { SelectItem } from './SelectInputField'
import reserveTrialLesson from '../../api/reserveTrialLesson'
import cancelTrialLesson from '../../api/cancelTrialLesson'
import { GtagUtil } from '../../utils/gtagUtils'
import { TrialLessonIndexView } from '../../types/trialLessons'
import { LessonTypeId } from '../LessonListContent'
import { sendCustomEventToBraze } from '../../utils/braze'
import { ISODateTime } from '../../types/date'

export const emailRegex = new RegExp(
  /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

const INTENTION_LEVEL_ITEMS: SelectItem[] = [
  { label: '入会を決めている', value: '100' },
  {
    label: '入会をほぼ決めており、気になる点が解消できたら入会したい',
    value: '80',
  },
  { label: '入会を前向きに検討しており、気になる点を解消したい', value: '60' },
  { label: '入会するか悩んでおり、気になる点を解消したい', value: '40' },
  { label: 'すぐに入会希望ではないが、まずは質問をしたい', value: '20' },
  { label: '入会を全く考えておらず、質問も特にない', value: '0' },
]

const PREFECTURE_ITEMS: SelectItem[] = [
  { label: '北海道', value: '北海道' },
  { label: '青森県', value: '青森県' },
  { label: '岩手県', value: '岩手県' },
  { label: '宮城県', value: '宮城県' },
  { label: '秋田県', value: '秋田県' },
  { label: '山形県', value: '山形県' },
  { label: '福島県', value: '福島県' },
  { label: '茨城県', value: '茨城県' },
  { label: '栃木県', value: '栃木県' },
  { label: '群馬県', value: '群馬県' },
  { label: '埼玉県', value: '埼玉県' },
  { label: '千葉県', value: '千葉県' },
  { label: '東京都', value: '東京都' },
  { label: '神奈川県', value: '神奈川県' },
  { label: '新潟県', value: '新潟県' },
  { label: '富山県', value: '富山県' },
  { label: '石川県', value: '石川県' },
  { label: '福井県', value: '福井県' },
  { label: '山梨県', value: '山梨県' },
  { label: '長野県', value: '長野県' },
  { label: '岐阜県', value: '岐阜県' },
  { label: '静岡県', value: '静岡県' },
  { label: '愛知県', value: '愛知県' },
  { label: '三重県', value: '三重県' },
  { label: '滋賀県', value: '滋賀県' },
  { label: '京都府', value: '京都府' },
  { label: '大阪府', value: '大阪府' },
  { label: '兵庫県', value: '兵庫県' },
  { label: '奈良県', value: '奈良県' },
  { label: '和歌山県', value: '和歌山県' },
  { label: '鳥取県', value: '鳥取県' },
  { label: '島根県', value: '島根県' },
  { label: '岡山県', value: '岡山県' },
  { label: '広島県', value: '広島県' },
  { label: '山口県', value: '山口県' },
  { label: '徳島県', value: '徳島県' },
  { label: '香川県', value: '香川県' },
  { label: '愛媛県', value: '愛媛県' },
  { label: '高知県', value: '高知県' },
  { label: '福岡県', value: '福岡県' },
  { label: '佐賀県', value: '佐賀県' },
  { label: '長崎県', value: '長崎県' },
  { label: '熊本県', value: '熊本県' },
  { label: '大分県', value: '大分県' },
  { label: '宮崎県', value: '宮崎県' },
  { label: '鹿児島県', value: '鹿児島県' },
  { label: '沖縄県', value: '沖縄県' },
  { label: '海外・その他', value: '海外・その他' },
]

const INVALID_PASSWORD =
  'パスワードを正しく入力してください（半角英数字６文字以上）'

type FormType = {
  firstName: string
  lastName: string
  email: string
  phone_number: string
  birthday: string
  prefecture?: string
  password: string
  intention_level?: string
}

function Form({
  jwtToken,
  selectedLesson,
  currentUserEmail,
  currentUserBirthday,
  selectedLessonTypeId,
  isRescheduled,
  reservationId,
  isMulticreator,
}: {
  jwtToken: string
  selectedLesson: TrialLessonIndexView
  currentUserEmail: string | null
  currentUserBirthday: string | null
  currentUserPrefecture: string | null
  selectedLessonTypeId: LessonTypeId | null
  isRescheduled?: boolean
  reservationId?: string
  isMulticreator?: boolean
}) {
  const initialState: FormType = {
    firstName: '',
    lastName: '',
    email: '',
    phone_number: '',
    birthday: '1995-01-01',
    password: '',
  }

  const initialErrorState: FormType = {
    firstName: '',
    lastName: '',
    email: '',
    phone_number: '',
    birthday: '',
    password: '',
  }
  useEffect(() => {
    if (!isMulticreator) {
      initialState.intention_level = ''
      initialErrorState.intention_level = ''
      initialState.prefecture = ''
      initialErrorState.prefecture = ''
    }
  }, [isMulticreator])
  const router = useRouter()
  const [values, setValues] = useState(initialState)
  const [errors, setErrors] = useState(initialErrorState)
  const [isPassedValidate, setIsPassedValidate] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { value, name } = e.target
      setValues({ ...values, [name]: value })

      switch (name) {
        case 'lastName':
          GtagUtil.beginCheckoutStep4Name()
          break
        case 'firstName':
          GtagUtil.beginCheckoutStep4Name()
          break
        case 'email':
          GtagUtil.beginCheckoutStep4Email()
          break
        case 'phone_number':
          GtagUtil.beginCheckoutStep4PhoneNumber()
          break
        case 'password':
          GtagUtil.beginCheckoutStep4Password()
          break
      }
    },
    [values]
  )

  const handleReservationCompleted = (
    reservationId: string,
    reservationCreatedAt: ISODateTime,
    selectedLesson: TrialLessonIndexView,
    isMulticreator?: boolean
  ) => {
    const query = router.query
    const utm_source = query.utm_source || ''
    const utm_medium = query.utm_medium || ''
    const utm_campaign = query.utm_campaign || ''
    const utm_term = query.utm_term || ''
    const utm_content = query.utm_content || ''

    const lessonId = selectedLesson.id.toString()

    const isImmediateDataFlush = true

    sendCustomEventToBraze(
      isMulticreator ? 'likespro_trial_lessons' : 'likes_trial_lessons',
      {
        体験レッスン予約ID: reservationId,
        申込日時: reservationCreatedAt,
        体験レッスンID: lessonId,
        体験レッスン開催日時: selectedLesson.starts_at,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
      },
      isImmediateDataFlush
    )
  }

  useEffect(() => {
    if (
      Object.keys(errors).filter(error => {
        return (errors as any)[error]
      }).length === 0 &&
      Object.keys(values).filter(value => {
        return (values as any)[value]
      }).length === Object.keys(values).length
    ) {
      setIsPassedValidate(true)
    } else {
      setIsPassedValidate(false)
    }

    if (jwtToken) {
      // ログイン済みの場合
      // 1. MCCの場合は入会希望度が存在しないので true とする
      // 2. 入会希望度のみ or 生年月日＋入会希望度のフォームになる
      //    生年月日はデフォルト値があるので、入会希望度だけチェックをいれる
      if (
        isMulticreator ||
        (values.intention_level &&
          values.intention_level.length > 0 &&
          values.prefecture &&
          values.prefecture.length > 0)
      ) {
        setIsPassedValidate(true)
      } else {
        setIsPassedValidate(false)
      }
    }
  }, [errors, values, isMulticreator])

  // 名前(姓)
  const lastNameForm = (
    <InputField
      className={classNames(styles.input, {
        [styles.error]: errors.lastName,
      })}
      type="text"
      id="lastName"
      name="lastName"
      label="姓"
      placeholder="山田"
      defaultValue={(values as { [key: string]: string }).lastName}
      onChange={handleInputChange}
      onBlur={e => {
        const { value, name } = e.target
        setErrors({
          ...errors,
          [name]: value === '' ? '正しい値を入力してください' : '',
        })
      }}
      error={errors.lastName}
    />
  )

  // 名前(名)
  const firstNameForm = (
    <InputField
      className={classNames(styles.input, {
        [styles.error]: errors.firstName,
      })}
      type="text"
      id="firstName"
      name="firstName"
      label="名"
      placeholder="花子"
      defaultValue={(values as { [key: string]: string }).firstName}
      onChange={handleInputChange}
      onBlur={e => {
        const { value, name } = e.target
        setErrors({
          ...errors,
          [name]: value === '' ? '正しい値を入力してください' : '',
        })
      }}
      error={errors.firstName}
    />
  )

  // email
  const emailForm = (
    <>
      <InputField
        className={classNames(styles.input, {
          [styles.error]: errors.email,
        })}
        type="email"
        id="email"
        name="email"
        label="メールアドレス"
        placeholder="she@she-inc.co.jp"
        defaultValue={(values as { [key: string]: string }).email}
        onChange={handleInputChange}
        onBlur={e => {
          const { value, name } = e.target
          setErrors({
            ...errors,
            [name]: !emailRegex.test(value) ? '正しい値を入力してください' : '',
          })
        }}
        error={errors.email}
      />
      <div className={styles.emailAnnotationArea}>
        <p className={styles.emailAnnotation}>
          ※docomo、au、SoftBankなどの携帯メールをご利用の場合、各キャリアのセキュリティ設定のためメールが届かない場合がございます。
          <br />
          ※携帯キャリア以外のメールアドレス（Gmail,Yahoo!メールなど）でのご登録を推奨しております。
        </p>
      </div>
    </>
  )

  // 電話番号
  const phoneNumberForm = (
    <InputField
      className={classNames(styles.input, {
        [styles.error]: errors.phone_number,
      })}
      type="tel"
      id="phone"
      name="phone_number"
      label="電話番号"
      placeholder="080-xxxx-xxxx"
      defaultValue={(values as { [key: string]: string }).phone_number}
      onChange={handleInputChange}
      onBlur={e => {
        const { value, name } = e.target
        setErrors({
          ...errors,
          [name]: value === '' ? '正しい値を入力してください' : '',
        })
      }}
      error={errors.phone_number}
    />
  )

  // 生年月日
  const birthdayForm = (
    <BirthdayInputField
      className={classNames(styles.input, styles.select, {
        [styles.error]: errors.birthday,
      })}
      id="birthday"
      name="birthday"
      label="生年月日"
      defaultValue={(values as { [key: string]: string }).birthday}
      onChange={handleInputChange}
      onBlur={e => {
        const { value, name } = e.target
        setErrors({
          ...errors,
          [name]: value === '' ? '正しい値を入力してください' : '',
        })
      }}
      error={errors.birthday}
    />
  )

  // 居住地
  // マルチクリエーターの場合は取得しない
  const prefectureForm = !isMulticreator && (
    <SelectInputField
      className={classNames(styles.input, styles.select, {
        [styles.error]: errors.prefecture,
      })}
      id="prefecture"
      name="prefecture"
      label="居住地"
      placeholder="居住地を選択してください"
      defaultValue={(values as { [key: string]: string }).prefecture}
      items={PREFECTURE_ITEMS}
      onChange={handleInputChange}
      onBlur={e => {
        const { value, name } = e.target
        setErrors({
          ...errors,
          [name]: value === '' ? '正しい値を選択してください' : '',
        })
      }}
      error={errors.prefecture as string}
    />
  )

  // パスワード
  const passwordForm = (
    <InputField
      className={classNames(styles.input, {
        [styles.error]: errors.password,
      })}
      type="password"
      id="password"
      name="password"
      label="パスワード"
      placeholder="6文字以上"
      defaultValue={(values as { [key: string]: string }).password}
      onChange={handleInputChange}
      onBlur={e => {
        const { value, name } = e.target
        setErrors({
          ...errors,
          [name]: value === '' ? INVALID_PASSWORD : '',
        })
      }}
      error={errors.password}
    />
  )

  // 入会希望度/
  // マルチクリエータの場合は取得しない
  const intentionLevelForm = !isMulticreator && (
    <SelectInputField
      className={classNames(styles.input, styles.select, {
        [styles.error]: errors.intention_level,
      })}
      id="intention_level"
      name="intention_level"
      label="入会希望度"
      placeholder="選択してください"
      defaultValue={(values as { [key: string]: string }).intention_level}
      items={INTENTION_LEVEL_ITEMS}
      onChange={handleInputChange}
      onBlur={e => {
        const { value, name } = e.target
        setErrors({
          ...errors,
          [name]: value === '' ? '正しい値を入力してください' : '',
        })
      }}
      error={errors.intention_level as string}
    />
  )

  return (
    <form>
      {!jwtToken ? (
        // 未ログインの場合は全ての入力欄を表示
        <>
          {lastNameForm}
          {firstNameForm}
          {emailForm}
          {phoneNumberForm}
          {prefectureForm}
          {birthdayForm}
          {intentionLevelForm}
          {passwordForm}
        </>
      ) : !currentUserBirthday ? (
        // ログイン済み & user.birthdayが存在しない場合は、居住地と生年月日、入会希望度
        <>
          {prefectureForm}
          {birthdayForm}
          {intentionLevelForm}
        </>
      ) : (
        // ログイン済み & user.birthdayがすでに存在する場合は、居住地と入会希望度のみ
        <>
          {prefectureForm}
          {intentionLevelForm}
        </>
      )}
      <div className={styles.formButtonWrapper}>
        <ReservationButton
          id="submit-button"
          loading={loading}
          onClick={e => {
            e.preventDefault()
            if (!isPassedValidate) {
              return
            }
            setLoading(true)
            const lessonId = selectedLesson.id
            // ログイン判定をjwtTokenが存在しているかで行う
            const isSignedIn = !!jwtToken
            const intention_level =
              !isMulticreator &&
              (values as { [key: string]: string })?.intention_level
            const prefecture =
              !isMulticreator &&
              (values as { [key: string]: string })?.prefecture
            const birthday = (values as { [key: string]: string })?.birthday
            const fullName = `${values.lastName}${values.firstName}`.trim()

            // isRescheduled の場合 既存予約のキャンセルも行う

            reserveTrialLesson(
              jwtToken,
              lessonId.toString(),
              // 未ログインの場合は全て送信
              // ログイン済み & user.birthdayが存在しない場合は、生年月日と入会希望度を送信
              // ログイン済み & user.birthdayがすでに存在する場合は、入会希望度のみ送信
              !isSignedIn
                ? { ...values, name: fullName }
                : !currentUserBirthday
                ? { birthday, intention_level, prefecture }
                : { intention_level, prefecture },
              isSignedIn
            )
              .then(({ error, body }) => {
                if (error) {
                  setLoading(false)
                  if (typeof body === 'string') {
                    window.alert(body)
                    return
                  }

                  const err: { [s: string]: string | React.ReactNode } = {}
                  Object.keys(body).forEach(_body => {
                    // We have to show suitable error message then it isn't used original error a message from API
                    // https://github.com/sheinc/she_webapp/blob/03f48571a63992c34ef87344d771b54136a18729/config/locales/en.yml#L61
                    if (
                      _body === 'password' &&
                      body.password?.[0].indexOf('短すぎます') === 0
                    ) {
                      err.password = INVALID_PASSWORD
                    } else if (
                      _body === 'email' &&
                      body.email?.[0] === 'すでに使用されています'
                    ) {
                      err.email = (
                        <>
                          このメールアドレスは会員登録済みです。
                          <br />
                          <a
                            className={styles.link}
                            href={`${
                              process.env.NEXT_PUBLIC_LOGIN_URL
                            }/sign_in?redirect_url=${encodeURIComponent(
                              location.href
                            )}`}
                          >
                            マイページ
                          </a>
                          にログイン後、再度お申込みください。
                        </>
                      )
                    } else {
                      err[_body] = body[_body][0]
                    }
                  })

                  setErrors({
                    ...errors,
                    ...err,
                  })
                } else {
                  handleReservationCompleted(
                    body.id,
                    body.created_at,
                    selectedLesson,
                    isMulticreator
                  )

                  const email = currentUserEmail ?? values?.email
                  GtagUtil.reportTrialLessonReservationCompletion(
                    body['reserver_id'],
                    selectedLesson,
                    body.id,
                    email,
                    !isMulticreator
                      ? parseInt(intention_level?.toString(), 10)
                      : undefined
                  )
                  const paramsObj: { [key: string]: string } = {
                    lesson_id: String(lessonId),
                    lesson_type:
                      body?.reservable?.target === 'expert'
                        ? 'expert'
                        : selectedLessonTypeId === 'class'
                        ? 'otl'
                        : 'ons',
                    // Note: 予約IDの下一桁を取得する
                    // See: https://www.notion.so/sheinc/a73e55237f0f4d5f98e253744f9f5b03?pvs=4#58e3bc2856f04ee7b9c715f7ce537b84
                    ri: String(body.id).slice(-1),
                  }
                  if (intention_level) {
                    // Note: 申込意向度の1の位の0を削除する
                    // See: https://sheinc.slack.com/archives/C03P1FPDRQX/p1702367657544059?thread_ts=1702300009.236329&cid=C03P1FPDRQX
                    paramsObj.il =
                      intention_level?.replace(/0$/, '') || intention_level
                  }
                  if (isRescheduled) {
                    paramsObj.is_rescheduled = 'true'
                  }

                  const searchParams = new URLSearchParams(paramsObj)

                  router.push(
                    `/trial_lessons/reservation_complete?${searchParams.toString()}`
                  )
                }
              })
              .catch(() => {
                setLoading(false)
              })
            if (isRescheduled) {
              // 振替の場合元の予約をcancelするAPIを叩かないといけないので裏でやっておく (ユーザーには何も表示させない)
              cancelTrialLesson(jwtToken, reservationId as string)
            }
          }}
          disabled={!isPassedValidate}
        >
          {isRescheduled
            ? '上記内容で振り替える（無料）'
            : '上記内容で予約する（無料）'}
        </ReservationButton>
      </div>
    </form>
  )
}

export default Form
