import { FOR_ONE_DAY, FOR_ONE_WEEK, timestampToTime } from './timestampToTime'
import { LessonData } from '../types/trialLessons'

// https://linear.app/she-inc/issue/GWH-841
// 年末年始の表記変更
const decemberSpecialPeriod = [
  { month: 11, date: 31, start_date: '2025-01-04', end_date: '2025-01-10' },
  { month: 11, date: 30, start_date: '2025-01-04', end_date: '2025-01-10' },
  { month: 11, date: 29, start_date: '2025-01-04', end_date: '2025-01-10' },
  { month: 11, date: 28, start_date: '2024-12-28', end_date: '2025-01-09' },
  { month: 11, date: 27, start_date: '2024-12-27', end_date: '2025-01-08' },
  { month: 11, date: 26, start_date: '2024-12-26', end_date: '2025-01-07' },
]

const januarySpecialPeriod = [
  { month: 0, date: 4, start_date: '2025-01-04', end_date: '2025-01-10' },
  { month: 0, date: 3, start_date: '2025-01-04', end_date: '2025-01-10' },
  { month: 0, date: 2, start_date: '2025-01-04', end_date: '2025-01-10' },
  { month: 0, date: 1, start_date: '2025-01-04', end_date: '2025-01-10' },
]

function findSpecialPeriod(
  now: Date,
  periods: {
    month: number
    date: number
    start_date: string
    end_date: string
  }[],
  boundaryTime: number
) {
  for (const { month, date, start_date, end_date } of periods) {
    if (
      now.getMonth() === month &&
      (now.getDate() === date ||
        (now.getDate() === date - 1 && now.getHours() >= boundaryTime))
    ) {
      return { start_date, end_date }
    }
  }
  return null
}

export const getLessonPeriod = ({
  boundaryTime,
  date,
}: {
  boundaryTime: number
  date?: number
}) => {
  const now = date ? new Date(date) : new Date()
  let baseTime = now.getTime()

  // 年末の特定日設定
  const decemberPeriod = findSpecialPeriod(
    now,
    decemberSpecialPeriod,
    boundaryTime
  )
  if (decemberPeriod) {
    return decemberPeriod
  }

  // 年始の特定日設定
  const januaryPeriod = findSpecialPeriod(
    now,
    januarySpecialPeriod,
    boundaryTime
  )
  if (januaryPeriod) {
    return januaryPeriod
  }

  // 通常の処理: 20時以降は翌日開始、それ以外は当日開始
  if (now.getHours() >= boundaryTime) {
    baseTime += FOR_ONE_DAY
  }

  return {
    start_date: timestampToTime(baseTime),
    end_date: timestampToTime(baseTime + FOR_ONE_WEEK),
  }
}

export const excludeLessonData = (body: LessonData) => {
  const now = new Date()
  if (now.getDate() < 26 && now.getMonth() === 11) {
    return body
  }

  // お休み期間リスト
  const excludeDates = [
    '2024-12-29',
    '2024-12-30',
    '2024-12-31',
    '2025-01-01',
    '2025-01-02',
    '2025-01-03',
  ]

  // 除外日のデータを除く処理
  return Object.fromEntries(
    Object.entries(body).filter(([key]) => !excludeDates.includes(key))
  )
}
