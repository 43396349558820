const API_KEY = process.env.NEXT_PUBLIC_BRAZE_API_KEY
const ENDPOINT = process.env.NEXT_PUBLIC_BRAZE_ENDPOINT

export const initializeBraze = async (user?: any) => {
  if (!API_KEY || !ENDPOINT || typeof window === 'undefined') return

  const braze = await import('@braze/web-sdk')

  // Initialize the Braze SDK
  if (!braze.isInitialized()) {
    braze.initialize(API_KEY, {
      baseUrl: ENDPOINT,
    })
  }

  // Optionally set the current user's external ID before starting a new session
  if (user && user[0]?.id) {
    braze.changeUser(user[0].id)
  }

  // Open the session
  braze.openSession()
}

export const sendCustomEventToBraze = async (
  eventName: string,
  eventProperties: Record<string, any> = {},
  isImmediate = false
) => {
  const braze = await import('@braze/web-sdk')

  // Send a custom event to Braze
  braze.logCustomEvent(eventName, eventProperties)

  if (isImmediate) {
    // Request immediate data flush
    braze.requestImmediateDataFlush()
  }
}
